import React from "react";
import './FullWidthImageSection.scss';
import XiaohoImage from "../../assets/images/xiaoho_images.png";

function FullWidthImageSection({header, body}){
    return(
        <section className="full-width-image-section">
            <div className="full-width-image-section__section-container">
                <div className="full-width-image-section__text-wrapper">
                    <h2 className="full-width-image-section__text-wrapper--header">{header}</h2>
                    <p className="full-width-image-section__text-wrapper--body">{body}</p>
                </div>
            </div>

            <div className="full-width-image-section__image-wrapper">
                <img className="full-width-image-section__image-wrapper--image" src={XiaohoImage} alt="Xiaoho's images" />
            </div>
        </section>
    );
}

export default FullWidthImageSection;