import React from "react";
import './Projects.scss';
import Header from "../../components/Header/Header";

function Projects(){
    return(
        <div className="page-layout">
            <Header />
            <main className="page-layout-main">
            </main>
        </div>
    );
};

export default Projects;