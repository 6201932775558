import React from "react";
import CardTile from "../../components/CardTile/CardTile";
import './CardsSection.scss'
import cardsData from "../../data/work-gallery.json"

function CardsSection({header, body}){
    return(
        <section className="cards-section">
            <div className="cards-section__section-container">
                <div className="cards-section__text-wrapper">
                    <h2 className="cards-section__text-wrapper--header">{header}</h2>
                    <p className="cards-section__text-wrapper--body">{body}</p>
                </div>

                <div className="cards-section__cards-tile-wrapper">
                    {cardsData.map (card => (<CardTile 
                        image={card.image}
                        header={card.title}
                        body={card.body}
                        url={card.url}
                        isExternal={true}
                    />
                ))}
                </div>
            </div>
        </section>
    );
}

export default CardsSection;