import React from "react";
import './IconLink.scss'

function IconLink({icon, isLink, onClick, url}){
    return isLink?(
        <a className="icon-link" href={url} target="_blank">
            <div className="icon-link__wrapper">
                <i className={icon}></i>
            </div>
        </a>
    ):(
        <button className="icon-link" onClick={onClick}>
            <div className="icon-link__wrapper">
                <i className={icon}></i>
            </div>
        </button>
    );
}

export default IconLink;