import React from "react";
import './Banner.scss';
import Badge from "../Badge/Badge";

function Banner({header, body, badgeTextRight, badgeTextLeft}){
    return(
        <section className="banner">
            <div className="banner__secction-container">
                <div className="banner__section-upper">
                    <h2 className="banner__section-upper--header">{header}</h2>
                    <p className="banner__section-upper--body">{body}</p>
                </div>

                <div className="banner__section-bottom">
                    <Badge 
                    textRight={badgeTextRight} 
                    textLeft={badgeTextLeft} 
                    />
                </div>
            </div>
        </section>
    );
}

export default Banner;