import React from "react";
import './Footer.scss';
import { useState } from "react";

function Footer(){
    // add timstamp on the footer
    const [time, setTime] = useState("");

    return(
        <section className="footer">
            <p className="footer__text">© 2024 Portfolio by Sony Chen. All rights reserved.</p>
        </section>

    );
}

export default Footer;