import React from "react";
import './TextLink.scss'

function TextLink({text, url}){
    return(
        <a className="text-link" href={url}>
            <div className="text-link__inner">
                {text}
            </div>
        </a>
    );
}

export default TextLink;