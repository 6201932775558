import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import './DragableElm.scss'

const DraggableEmoji = ({}) => {
  const [viewportSize, setViewportSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setViewportSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const emojis = ['😀', '🎉', '🚀', '🌟'];

  const calculatePosition = (index) => {
    const offset = 100;
    switch(index) {
      case 0:
        return { x: viewportSize.width / 1.9 - offset, y: viewportSize.height / 1.8 - offset };
      case 1:
        return { x: viewportSize.width / 2.5 + offset, y: viewportSize.height / 1.9 - offset };
      case 2:
        return { x: viewportSize.width / 2.3 - offset, y: viewportSize.height / 2.3 + offset };
      case 3:
        return { x: viewportSize.width / 2 + offset, y: viewportSize.height / 2.3 + offset };
      default:
        return { x: viewportSize.width / 2, y: viewportSize.height / 2 };
    }
  };

  return (
    <div className='emoji-move'>
      {emojis.map((emoji, index) => (
        <Draggable key={index} bounds="parent">
          <div className='emoji-move__emoji' style={{
            top: `${calculatePosition(index).y}px`,
            left: `${calculatePosition(index).x}px`,
          }}>
            {emoji}
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default DraggableEmoji;