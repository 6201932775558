import React from "react";
import './Badge.scss';

function Badge({textRight, textLeft}){
    return(
        <div className="badge-main-wrapper">
            <div className="badge-desktop">
                <span className="badge-desktop__text">{textRight} 
                <span className="badge-desktop__text--divider"> | </span> 
                {textLeft}</span>
            </div>

            <div className="badge-mobile">
                <div className="badge-mobile__wrapper--margin-bottom">
                    <span className="badge-mobile__text">{textRight}</span>
                </div>

                <div className="badge-mobile__wrapper">
                    <span className="badge-mobile__text">{textLeft}</span>
                </div>
            </div>
        </div>
    );
}

export default Badge;