import React from "react";
import { Link } from "react-router-dom";
import './CardTile.scss'


function CardTile({image, alt, header, body, isExternal, internalLink, url}){
    return isExternal?(
        <a className="card-tile" href={url}>
            <div className="card-tile__image-wrapper">
                <img className="card-tile__image" src={image} alt={alt} />
            </div>

            <div className="card-tile__text-wrapper">
                <h4 className="card-tile__text-wrapper--header">{header}</h4>
                <p className="card-tile__text-wrapper--body">{body}</p>
            </div>
        </a>
    ):(
        <Link to={internalLink} className="card-tile">
        <div className="card-tile__image-wrapper">
            <img className="card-tile__image" src={image} alt={alt} />
        </div>

        <div className="card-tile__text-wrapper">
            <h4 className="card-tile__text-wrapper--header">{header}</h4>
            <p className="card-tile__text-wrapper--body">{body}</p>
        </div>
    </Link>
    )
}

export default CardTile;