import React from "react";
import './Hero.scss';
import heroImage from "../../assets/images/sony_in_frame.png";

function Hero({header, body}){
    return(
        <section className="hero">
            <div className="hero__section-container">
                <div className="hero__text-wrapper">
                    <h1 className="hero__text-wrapper--header">{header}</h1>
                    <p className="hero__text-wrapper--body">{body}</p>
                </div>

                <div className="hero__image-wrapper">
                    <img className="hero__image-wrapper--image" src={heroImage} alt="Sony's illustration" />
                </div>
            </div>
        </section>
    );
}

export default Hero;