import React from "react";
import { useState } from "react";
import './Home.scss';
import Header from "../../components/Header/Header";
import Hero from "../../templates/Hero/Hero";
import Banner from "../../components/Banner/Banner";
import Footer from "../../components/Footer/Footer";
import CardsSection from "../../templates/CardsSection/CardsSection";
import FullWidthImageSection from "../../templates/FullWidthImageSection/FullWidthImageSection";
import DrageableElm from "../../components/DragableElm/DragableElm";
import DragableElmData from "../../data/dragable-elm.json"

function Home(){

    return(
        <div className="page-layout">
            <Header />
            <div className="page-layout-main">
                <div className="drag">
                    <DrageableElm />
                </div>
                <main>
                    <Hero 
                    header="Product Designer, multi-disciplinary or a magician✨"
                    body="I’m Sony Chen, a senior product designer with a background in industrial design. I’m also a bubble tea enthusiast. My favorite ratio is black tea with milk, 30% sugar, and less ice."
                    />

                    <Banner 
                    header="Clean UI + Efficient UX + magic = Sony" 
                    body="My co-worker calls me “Super Sony” because I’m a fast learner with a touch of OCD (except when it comes to my room), always striving to achieve my projects to perfection. And of course, I run on bubble tea!" 
                    badgeTextRight="Currently @ Capital One" 
                    badgeTextLeft="Formerly @ Voyager"
                    />

                    <CardsSection 
                    header="Work galley"
                    body="I’m migrating my site to a React library. During this process, you might see a mix of old and new elements. For portfolio details, please visit these sections."
                    />

                    <FullWidthImageSection 
                    header="I just wanted to show you my dog"
                    body="Outside of work and all the design stuff, I love hanging out with my dog. We spend our time playing fetch, going for walks, and soaking up the sunshine together in the summer."
                    />
                </main>

                <footer>
                    <Footer />
                </footer>
            </div>
        </div>
    );
};

export default Home;