import React, { useState } from 'react';
import './Logo.scss';
import { Link } from 'react-router-dom';
import SonyLogoStatic from '../../assets/images/logo-static.png';
import SonyLogoGif from '../../assets/gifs/logo.gif';

function Logo() {
  const [logoSrc, setLogoSrc] = useState(SonyLogoStatic);

  const handleMouseEnter = () => {
    setLogoSrc(SonyLogoGif);
  };

  const handleMouseLeave = () => {
    setLogoSrc(SonyLogoStatic);
  };

  const handleClick = () => {
    setLogoSrc(SonyLogoGif);
  };

  return (
    <Link
      className="logo link-rm-style"
      to="/"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img className="logo__logo-itself" src={logoSrc} alt="Sony's logo" />
    </Link>
  );
}

export default Logo;